import React, { lazy } from "react";
import { Route } from "react-router-dom";
import ViewOrdersPage from "../../components/agent/viewOrders/ViewOrders.page";
import { useLocation } from "react-router-dom";

import RequireAuth from "../../components/common/auth/RequireAuth";
const CreatePartnerDelays = lazy(() =>
  import(
    /* webpackChunkName: 'CreatePartnerDelays' */ "../../components/agent/partnerDelays/CreatePartnerDelays"
  )
);
const ViewPartnerDelays = lazy(() =>
  import(
    /* webpackChunkName: 'ViewPartnerDelays' */ "../../components/agent/partnerDelays/ViewPartnerDelays"
  )
);
const User = lazy(() =>
  import(/* webpackChunkName: 'User' */ "../../components/common/user/User")
);
const ChangePassword = lazy(() =>
  import(
    /* webpackChunkName: 'ChangePassword' */ "../../components/common/changePassword/ChangePassword"
  )
);
const AddAgentApplications = lazy(() =>
  import(
    /* webpackChunkName: 'AddAgentApplications' */ "../../components/agent/agentApplications/AddAgentApplications"
  )
);
const EditAgentApplications = lazy(() =>
  import(
    /* webpackChunkName: 'EditAgentApplications' */ "../../components/agent/agentApplications/EditAgentApplications"
  )
);
const AgentApplications = lazy(() =>
  import(
    /* webpackChunkName: 'AgentApplications' */ "../../components/agent/agentApplications/AgentApplications"
  )
);
const ChangeTrackerStatusSuccess = lazy(() =>
  import(
    /* webpackChunkName: 'ChangeTrackerStatusSuccess' */ "../../components/agent/changeTrackerStatusCSV/ChangeTrackerStatusSuccess"
  )
);
const ChangeTrackerStatusCSV = lazy(() =>
  import(
    /* webpackChunkName: 'ChangeTrackerStatusCSV' */ "../../components/agent/changeTrackerStatusCSV/ChangeTrackerStatusCSV"
  )
);
const AgentViewOrderDetails = lazy(() =>
  import(
    /* webpackChunkName: 'AgentViewOrderDetails' */ "../../components/agent/viewOrders/AgentViewOrderDetails"
  )
);
const ViewTrackerUpdateDetails = lazy(() =>
  import(
    /* webpackChunkName: 'ViewTrackerUpdateDetails' */ "../../components/agent/trackerUpdate/ViewTrackerUpdateDetails"
  )
);
const ViewTrackerUpdate = lazy(() =>
  import(
    /* webpackChunkName: 'ViewTrackerUpdate' */ "../../components/agent/trackerUpdate/ViewTrackerUpdate"
  )
);
const CreateTrackerUpdate = lazy(() =>
  import(
    /* webpackChunkName: 'CreateTrackerUpdate' */ "../../components/agent/trackerUpdate/CreateTrackerUpdate"
  )
);
const CreateAwb = lazy(() =>
  import(
    /* webpackChunkName: 'ViewAwb' */ "../../components/agent/midMileAwb/CreateAwb.page"
  )
);
const CreateNpDelayReason = lazy(() =>
  import(
    /* webpackChunkName: 'CreateNpDelayReason' */ "../../components/agent/npDelayReason/CreateNpDelayReason"
  )
);
// const ViewBillDetails = lazy(() =>
//   import(
//     /* webpackChunkName: 'ViewBillDetails' */ "../../components/agent/bills/ViewBillDetails"
//   )
// );
// const ViewBills = lazy(() =>
//   import(
//     /* webpackChunkName: 'ViewBills' */ "../../components/agent/bills/ViewBills"
//   )
// );
const CreateBillsSuccess = lazy(() =>
  import(
    /* webpackChunkName: 'CreateBillsSuccess' */ "../../components/agent/bills/CreateBillsSuccess"
  )
);
const CreateBills = lazy(() =>
  import(
    /* webpackChunkName: 'CreateBills' */ "../../components/agent/bills/CreateBills"
  )
);
const DeleteOrderByUploadBatchNoSuccess = lazy(() =>
  import(
    /* webpackChunkName: 'DeleteOrderByUploadBatchNoSuccess' */ "../../components/agent/deleteOrderByUploadBatchNo/DeleteOrderByUploadBatchNoSuccess"
  )
);
const DeleteOrderByUploadBatchNo = lazy(() =>
  import(
    /* webpackChunkName: 'DeleteOrderByUploadBatchNo' */ "../../components/agent/deleteOrderByUploadBatchNo/DeleteOrderByUploadBatchNo"
  )
);
const Reports = lazy(() =>
  import(
    /* webpackChunkName: 'Reports' */ "../../components/agent/reports/Reports"
  )
);
const UploadPricingCSVPage = lazy(() =>
  import(
    /* webpackChunkName: 'UploadPricingCSVPage' */ "../../components/agent/pricing/UploadPricingCSV.page"
  )
);
const ViewPricingDetails = lazy(() =>
  import(
    /* webpackChunkName: 'ViewPricingDetails' */ "../../components/agent/pricing/ViewPricingDetails"
  )
);
const UploadPricingCSV = lazy(() =>
  import(
    /* webpackChunkName: 'UploadPricingCSV' */ "../../components/agent/pricing/UploadPricingCSV"
  )
);
const WeightReconciliation = lazy(() =>
  import(
    /* webpackChunkName: 'WeightReconcilation' */ "../../components/agent/weightReconciliation/WeightReconciliation"
  )
);
const UploadAdhocCSV = lazy(() =>
  import(
    /* webpackChunkName: 'UploadAdhocCSV' */ "../../components/agent/uploadAdhocCSV/UploadAdhocCSV"
  )
);
const AgentStats = lazy(() =>
  import(
    /* webpackChunkName: 'AgentStats' */ "../../components/agent/AgentStats"
  )
);
const CreateCustomBatchPage = lazy(() =>
  import(
    /* webpackChunkName: 'CreateCustomBatchPage' */ "../../components/agent/orderBatch/CreateCustomBatchPage"
  )
);
// const MerchantDue = lazy(() =>
//   import(
//     /* webpackChunkName: 'MerchantDue' */ "../../components/agent/CODManagement/MerchantDues"
//   )
// );
const MerchantRemittance = lazy(() =>
  import(
    /* webpackChunkName: 'MerchantRemittance' */ "../../components/agent/CODManagement/MerchantRemittance"
  )
);
// const PartnerDues = lazy(() =>
//   import(
//     /* webpackChunkName: 'PartnerDues' */ "../../components/agent/CODManagement/PartnerDues"
//   )
// );
const PartnerRemittance = lazy(() =>
  import(
    /* webpackChunkName: 'PartnerRemittance' */ "../../components/agent/CODManagement/PartnerRemittance"
  )
);
const AgentViewOrders = lazy(() =>
  import(
    /* webpackChunkName: 'AgentViewOrders' */ "../../components/agent/viewOrders/AgentViewOrders"
  )
);
const RequestConsigneeDocumentsPage = lazy(() =>
  import(
    /* webpackChunkName: 'RequestConsigneeDocumentsPage' */ "../../components/agent/requestConsigneeDocuments/RequestConsigneeDocumentsPage"
  )
);
const CorrectOrderAddress = lazy(() =>
  import(
    /* webpackChunkName: 'CorrectOrderAddress' */ "../../components/agent/correctOrderAddress/CorrectOrderAddress"
  )
);
const DonwloadPODs = lazy(() =>
  import(
    /* webpackChunkName: 'DonwloadPODs' */ "../../components/agent/order/downloadPODsV2/PodContainer"
  )
);

const DownloadShopeeOrders = lazy(() =>
  import(
    /* webpackChunkName: 'DownloadShopeeOrders' */ "../../components/agent/order/downloadShopeeOrders/DownloadShopeeOdrers.page"
  )
);

const SubmitReturnOrdersPage = lazy(() =>
  import(
    /* webpackChunkName: 'SubmitReturnOrdersPage' */ "../../components/agent/order/returnOrders/SubmitReturnOrders.page"
  )
);
const SubmitReturnOrderPage = lazy(() =>
  import(
    /* webpackChunkName: 'SubmitReturnOrderPage' */ "../../components/agent/order/returnOrder/SubmitReturnOrder.page"
  )
);
const ViewOrderDetails = lazy(() =>
  import(
    /* webpackChunkName: 'ViewOrderDetails.page' */ "../../components/agent/order/viewOrderDetails/ViewOrderDetails.page"
  )
);
// const ManageOrderExceptions = lazy(() =>
//   import(
//     /* webpackChunkName: 'ManageOrderExceptions.page' */ "../../components/agent/orderExceptions/ManageExceptions.page"
//   )
// );
// const ResolveOrderExceptions = lazy(() =>
//   import(
//     /* webpackChunkName: 'ResolveOrderExceptions.page' */ "../../components/agent/orderExceptions/ResolveOrderExceptions.page"
//   )
// );
const ManageMidmile = lazy(() =>
  import(
    /* webpackChunkName: 'ManageMidmile' */ "../../components/agent/midMileAwb/MidmileAwb.page"
  )
);
const ViewMidmileAwb = lazy(() =>
  import(
    /* webpackChunkName: 'ViewMidmile' */ "../../components/agent/midMileAwb/viewAwbDetails/ViewAwbDetails.page"
  )
);
const ManagePickupCapacity = lazy(() =>
  import(
    /* webpackChunkName: 'ManagePickupCapacity' */ "../../components/agent/RTSpickupCapacityManagement/ManagePickupCapacity.page"
  )
);
const CreatePickupCapacity = lazy(() =>
  import(
    /* webpackChunkName: 'CreatePickupCapacity' */ "../../components/agent/RTSpickupCapacityManagement/CreatePickupCapacity.page"
  )
);
const LspUsersPage = lazy(() =>
  import(
    /* webpackChunkName: 'CreatePickupCapacity' */ "../../components/agent/lspUsers/LspUsers.page"
  )
);
const CreateLspUser = lazy(() =>
  import(
    /* webpackChunkName: 'CreatePickupCapacity' */ "../../components/agent/lspUsers/CreateLspUser.page"
  )
);
const EditLspUser = lazy(() =>
  import(
    /* webpackChunkName: 'CreatePickupCapacity' */ "../../components/agent/lspUsers/EditLspUser.page"
  )
);
const Zones = lazy(() =>
  import("../../components/agent/zoneLocations/ZonesList")
);
const ZoneLocations = lazy(() =>
  import("../../components/agent/zoneLocations/ZoneLocations")
);
const NetworkPartnerAllocations = lazy(() =>
  import(
    "../../components/agent/postalCodeAllocation/NetworkPartnerAllocations"
  )
);
const SLANetworkEditor = lazy(() =>
  import("../../components/agent/slaNetworkEditor/SLANetworkEditor")
);
const CreateSLAJourney = lazy(() =>
  import("../../components/agent/slaNetworkEditor/CreateSLAJourney")
);
const ViewSLADetails = lazy(() =>
  import("../../components/agent/slaNetworkEditor/ViewSLADetails")
);
const EditSLA = lazy(() =>
  import("../../components/agent/slaNetworkEditor/EditSLA")
);

const ShopeeCartonsPage = lazy(() => import("../../pages/shopee/ShopeeCartons.page"))

//  TODO DELETE ALL CODE RELATED TO THESE COMPONENTS
// const ViewNoteDetails = lazy(() => import(/* webpackChunkName: 'ViewNoteDetails' */'../../components/agent/notes/ViewNoteDetails'));
// const ViewNotes = lazy(() => import(/* webpackChunkName: 'ViewNotes' */'../../components/agent/notes/ViewNotes'));
// const NotesReport = lazy(() => import(/* webpackChunkName: 'NotesReport' */'../../components/agent/notesReport/NotesReport'));
// const NetworkPartnersPage = lazy(() => import(/* webpackChunkName: 'NetworkPartnersPage' */'../../components/agent/networkPartners/NetworkPartnersPage'));
// const NetworkPartnersAddPage = lazy(() => import(/* webpackChunkName: 'NetworkPartnersAddPage' */'../../components/agent/networkPartners/NetworkPartnersAddPage'));
// const NetworkPartnerEditPage = lazy(() => import(/* webpackChunkName: 'NetworkPartnerEditPage' */'../../components/agent/networkPartners/NetworkPartnerEditPage'));
// const CreateNotesByCSV = lazy(() => import(/* webpackChunkName: 'CreateNoteByCSV' */'../../components/agent/notes/CreateNotesByCSV'));
// const ViewMawb = lazy(() => import(/* webpackChunkName: 'ViewMawb' */'../../components/agent/mawb/ViewMawb'));
// const CreateMawb = lazy(() => import(/* webpackChunkName: 'ViewMawb' */'../../components/agent/mawb/CreateMawb'));
// const CreateNotesSuccess = lazy(() => import(/* webpackChunkName: 'CreateNoteSuccess' */'../../components/agent/notes/CreateNotesSuccess'));
// const ViewPricing = lazy(() => import(/* webpackChunkName: 'ViewPricing' */'../../components/agent/pricing/ViewPricing'));
// const ViewOrdersPage = lazy(() => import(/* webpackChunkName: 'ViewOrdersPage' */'../../components/agent/order/viewOrders/ViewOrders.page'));

const ManageAwbRoute = (props) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const mawbClient = searchParams.get('mawb_client');
  const AuthenticatedManageMidmile = RequireAuth(ManageMidmile);
  return <AuthenticatedManageMidmile {...props} key={mawbClient} />
};

export const agentRoutes = [
  <Route key={0} path="/agent/user" component={RequireAuth(User)} />,
  <Route
    key={1}
    path="/agent/change-password"
    component={RequireAuth(ChangePassword)}
  />,
  <Route
    key={3}
    path="/agent/add-agent-apps"
    component={RequireAuth(AddAgentApplications)}
  />,
  <Route
    key={4}
    path="/agent/agent-apps/:id"
    component={RequireAuth(EditAgentApplications)}
  />,
  <Route
    key={5}
    path="/agent/agent-apps"
    component={RequireAuth(AgentApplications)}
  />,

  // shopee related pages
  // <Route
  //   key="shopee-cartons"
  //   path="/agent/shopee-cartons"
  //   component={RequireAuth(ShopeeCartonsPage)}
  // />,

  <Route
    key={11}
    path="/agent/change-tracker-status-csv/success"
    component={RequireAuth(ChangeTrackerStatusSuccess)}
  />,
  <Route
    key={12}
    path="/agent/change-tracker-status-csv"
    component={RequireAuth(ChangeTrackerStatusCSV)}
  />,
  <Route
    key={13}
    path="/agent/view-order-details/:id"
    component={RequireAuth(AgentViewOrderDetails)}
  />,
  <Route
    key={13}
    path="/agent/view-orders/:id"
    component={RequireAuth(ViewOrderDetails)}
  />,
  <Route
    key={14}
    path="/agent/view-orders-old"
    component={RequireAuth(AgentViewOrders)}
  />,
  <Route
    key={14}
    path="/agent/view-orders"
    component={RequireAuth(ViewOrdersPage)}
  />,
  <Route
    key={15}
    path="/agent/view-tracker-update-details/:id"
    component={RequireAuth(ViewTrackerUpdateDetails)}
  />,
  <Route
    key={16}
    path="/agent/view-tracker-updates"
    component={RequireAuth(ViewTrackerUpdate)}
  />,
  <Route
    key={17}
    path="/agent/create-tracker-update"
    component={RequireAuth(CreateTrackerUpdate)}
  />,
  // <Route
  //   key={22}
  //   path="/agent/view-bill-details/:id"
  //   component={RequireAuth(ViewBillDetails)}
  // />,
  // <Route
  //   key={23}
  //   path="/agent/view-bills"
  //   component={RequireAuth(ViewBills)}
  // />,
  <Route
    key={24}
    path="/agent/create-bills/success"
    component={RequireAuth(CreateBillsSuccess)}
  />,
  <Route
    key={25}
    path="/agent/create-bills"
    component={RequireAuth(CreateBills)}
  />,
  <Route
    key={30}
    path="/agent/delete-order-by-upload-batch-no/success"
    component={RequireAuth(DeleteOrderByUploadBatchNoSuccess)}
  />,
  // <Route
  //   key={31}
  //   path="/agent/delete-order-by-upload-batch-no"
  //   component={RequireAuth(DeleteOrderByUploadBatchNo)}
  // />,
  <Route key={32} path="/agent/reports" component={RequireAuth(Reports)} />,
  <Route
    key={35}
    path="/agent/view-pricing-details/:id"
    component={RequireAuth(ViewPricingDetails)}
  />,
  // <Route
  //   key={36}
  //   path="/agent/pricing"
  //   component={RequireAuth(UploadPricingCSVPage)}
  // />,
  <Route
    key={37}
    path="/agent/upload-pricing-csv"
    component={RequireAuth(UploadPricingCSV)}
  />,
  <Route
    key={38}
    path="/agent/weight-reconciliation"
    component={RequireAuth(WeightReconciliation)}
  />,
  <Route
    key={39}
    path="/agent/upload-adhoc-csv"
    component={RequireAuth(UploadAdhocCSV)}
  />,
  // <Route
  //   key={43}
  //   path="/agent/create-custom-batch/"
  //   component={RequireAuth(CreateCustomBatchPage)}
  // />,
  // <Route
  //   key={44}
  //   path="/agent/merchant-dues"
  //   component={RequireAuth(MerchantDue)}
  // />,
  <Route
    key={45}
    path="/agent/merchant-remittance"
    component={RequireAuth(MerchantRemittance)}
  />,
  // <Route
  //   key={46}
  //   path="/agent/partner-dues"
  //   component={RequireAuth(PartnerDues)}
  // />,
  <Route
    key={47}
    path="/agent/partner-remittance"
    component={RequireAuth(PartnerRemittance)}
  />,
  <Route
    key={48}
    path="/agent/correct-order-address"
    component={RequireAuth(CorrectOrderAddress)}
  />,
  <Route
    key={50}
    path="/agent/create-awb"
    component={RequireAuth(CreateAwb)}
  />,
  <Route
    key={51}
    path="/agent/download-pods"
    component={RequireAuth(DonwloadPODs)}
  />,
  <Route
    key={51}
    path="/agent/download-shopee-orders"
    component={RequireAuth(DownloadShopeeOrders)}
  />,
  <Route
    key={52}
    path="/agent/create-np-delay-reason"
    component={RequireAuth(CreateNpDelayReason)}
  />,

  <Route
    key="partner-delays"
    path="/agent/partner-delays/"
    exact={true}
    component={RequireAuth(ViewPartnerDelays)}
  />,
  <Route
    key="partner-delays"
    path="/agent/partner-delays/create/"
    exact={true}
    component={RequireAuth(CreatePartnerDelays)}
  />,

  // <Route
  //   key="request-consignee-documents"
  //   path="/agent/request-consignee-documents"
  //   component={RequireAuth(RequestConsigneeDocumentsPage)}
  // />,

  // <Route
  //   key="submit-return-orders"
  //   path="/agent/submit-return-orders"
  //   component={RequireAuth(SubmitReturnOrdersPage)}
  // />,

  <Route
    key="submit-return-order"
    path="/agent/submit-return-order"
    component={RequireAuth(SubmitReturnOrderPage)}
  />,

  // <Route
  //   key="rts-pickup-capacity-create"
  //   path="/agent/rts-pickup-capacity/create/"
  //   component={RequireAuth(CreatePickupCapacity)}
  // />,
  // <Route
  //   key="rts-pickup-capacity-list"
  //   path="/agent/rts-pickup-capacity"
  //   component={RequireAuth(ManagePickupCapacity)}
  // />,
  <Route
    key={`manage-mid-mile`} // Key based on mawbClient to force re-render
    path="/agent/manage-awb"
    component={ManageAwbRoute}
  />,
  <Route
    key="view-mid-mile"
    path="/agent/view-awb/:id"
    component={RequireAuth(ViewMidmileAwb)}
  />,

  // <Route
  //   key="lsp-users"
  //   path="/agent/lsp-users"
  //   component={RequireAuth(LspUsersPage)}
  // />,
  // <Route
  //   key="create-lsp-user"
  //   path="/agent/create-lsp-user"
  //   component={RequireAuth(CreateLspUser)}
  // />,
  // <Route
  //   key="edit-lsp-user"
  //   path="/agent/edit-lsp-user/:userId"
  //   component={RequireAuth(EditLspUser)}
  // />,

  <Route
    key={53}
    path="/agent/zone-locations/:id/:name/:postal_prefix?"
    component={RequireAuth(ZoneLocations)}
  />,
  <Route
    key={49}
    path="/agent/zone-locations/"
    component={RequireAuth(Zones)}
  />,
  <Route
    key={53}
    path="/agent/postal-code-allocation/"
    component={RequireAuth(NetworkPartnerAllocations)}
  />,
  // <Route
  //   key={20}
  //   path="/agent/network-editor/view-sla-journey/:type/:id"
  //   component={RequireAuth(ViewSLADetails)}
  // />,
  // <Route
  //   key={33}
  //   path="/agent/network-editor/create-sla-journey"
  //   component={RequireAuth(CreateSLAJourney)}
  // />,
  // <Route
  //   key={54}
  //   path="/agent/network-editor/edit-sla-journey/:type/:id"
  //   component={RequireAuth(EditSLA)}
  // />,
  // <Route
  //   key={21}
  //   path="/agent/network-editor"
  //   component={RequireAuth(SLANetworkEditor)}
  // />,

  <Route key={42} path="/agent" component={RequireAuth(AgentStats)} />,

  //  TODO DELETE ALL CODE RELATED TO THESE COMPONENTS
  // <Route key="manage-order-exceptions-resolve" path="/agent/manage-exceptions/resolve/" component={RequireAuth(ResolveOrderExceptions)} />,
  // <Route key="manage-order-exceptions" path="/agent/manage-exceptions" component={RequireAuth(ManageOrderExceptions)} />,
  // <Route key={49} path="/agent/view-mawb" component={RequireAuth(ViewMawb)} />,
  // <Route key={50} path="/agent/create-mawb" component={RequireAuth(CreateMawb)} />,
  // <Route key='network-partner-add' path='/agent/network-partners/add' exact={true} component={RequireAuth(NetworkPartnersAddPage)} />,
  // <Route key='network-partner-list' path='/agent/network-partners/' exact={true} component={RequireAuth(NetworkPartnersPage)} />,
  // <Route key='network-partner-edit' path='/agent/network-partners/:id' exact={true} component={RequireAuth(NetworkPartnerEditPage)} />,
  // <Route key={18} path="/agent/view-note-details/:id" component={RequireAuth(ViewNoteDetails)} />,
  // <Route key={19} path="/agent/view-notes" component={RequireAuth(ViewNotes)} />,
  // <Route key={20} path="/agent/create-notes/success" component={RequireAuth(CreateNotesSuccess)} />,
  // <Route key={33} path="/agent/notes-report" component={RequireAuth(NotesReport)} />,
  // <Route key={21} path="/agent/create-notes-csv" component={RequireAuth(CreateNotesByCSV)} />,
];
